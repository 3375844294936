import { getBrandSchema } from '@/utils/helpers';
import { TBrandItem } from '@/utils/brand-schemas';

import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';

Vue.use(Vuetify);

// Configure application branding
const schema: TBrandItem = getBrandSchema();

const theme = {
  themes: {
    light: {
      // set every color from pallet in TBrandItem
      ...schema.colors,
    },
  },
};
const opts = {
  theme,
  breakpoint: {
    mobileBreakpoint: 399,
    // custom branding breakpoints
    // https://www.figma.com/file/WFEPjGQJg5YrxjDP3ggBF9/Propvest---MVP?node-id=2%3A2
    // same config set in '@/styles/media.scss'
    thresholds: {
      xs: 399,
      sm: 719,
      md: 1023,
      lg: 1279,
      xl: 1440,
    },
  },
};

export default new Vuetify(opts);