










































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { getBrandSchema } from './utils/helpers';
import { TBrandItem } from './utils/brand-schemas';
import AppHeader from './components/common/AppHeader.vue';
import AppLoader from './components/common/AppLoader.vue';
import AppFooter from './components/common/AppFooter.vue';
import brandNames from '@/enum/brands';

@Component({
  name: 'App',
  components: {
    AppHeader,
    AppLoader,
    AppFooter,
  },
  computed: {
    ...mapGetters(['isLoaded']),
  },
})
export default class App extends Vue {
  $refs!: {
    app: HTMLElement;
  };

  private brandName: string = 'Exporo';
  locale: string = 'de';

  onLocaleSwitched(locale: string) {
    console.log(locale);
  }

  get title() {
    if (this.$route.path.indexOf('onboarding') > -1) {
      return this.onboardingTitle();
    } else if (this.$route.path.indexOf('signin') > -1) {
      return this.$t('user.amplify.Sign In') ;
    } else if (this.$route.path.indexOf('reset-password') > -1) {
      return this.$t('user.amplify.Reset password');
    }

    return undefined;
  }

  onboardingTitle() {
    return this.$vuetify.breakpoint.smAndUp
        ? this.$t('onboarding_title')
        : this.$t('onboarding');
  }

  get headerType() {
    return this.$route.path.includes('profile') ? 'secured' : 'focused';
  }

  get isExporoApp() {
    return !this.$route.path.includes('signin') 
      && (this.$route.path.includes('/app/profile') || this.$route.path.includes('/app/tax-information'));
  }

  env() {
    return process.env.VUE_APP_ENV;
  }

  get logoUrl() {
    if (this.$route.path.indexOf('signin') > -1) {
      return 'https://propvest.de/';
    } else if (this.$route.path.indexOf('reset-password') > -1) {
      return 'https://propvest.de/';
    }

    return `${process.env.VUE_APP_PROPVEST_DASHBOARD_URL}`;
  }

  get hasBaaderAccount() {
    if (this.user && this.headerType !== 'focused') {
      fetch(`${process.env.VUE_APP_BAADER_USER_API_URL}`, {
        headers: { authorization: 'Bearer ' + this.$store.getters.getToken.idToken },
      })
      .then((res) =>{
        return res.status === 200;
      })
      .catch((err) => console.error(err));
    }

    return false;
  }

  get user() {
    return this.$store.getters.user.user_id 
      ? encodeURI(
          JSON.stringify({
            userId: Number(this.$store.getters.user.user_id),
            firstName: this.$store.getters.naturalUser.firstName,
            lastName: this.$store.getters.naturalUser.lastName,
          })
        ) 
      : null;
  }

  get routes() {
    return encodeURI(
        JSON.stringify([
          {
            name: 'Dashboard',
            url: `${process.env.VUE_APP_PROPVEST_DASHBOARD_URL}`,
            title: 'Dashboard',
            icon: 'Dashboard',
            active: false,
          },
          {
            name: 'Portfolio',
            url: `${process.env.VUE_APP_PROPVEST_URL}/portfolio`,
            title: 'Portfolio',
            icon: 'Portfolio',
            active: false,
          },
          {
            name: null,
            url: process.env.VUE_APP_PROPVEST_MARKETPLACE,
            title: 'Invest',
            icon: 'Invest',
            active: false,
          },
        ])
    );
  }

  get isPropvest() {
    return this.brandName === brandNames.PROPVEST;
  }

  addScript(src: string) {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = src;
    document.head.appendChild(script);
  }

  mounted() {
    this.addScript('https://cdn.brand.propvest.com/header.umd.js');
    this.addScript('https://cdn.brand.propvest.com/footer.umd.js');
    this.addScript('https://cdn.brand.propvest.com/button.umd.js');

    const { $i18n } = Vue.prototype;

    $i18n.on('loaded', () => {
      this.locale = $i18n.language.indexOf('de') > -1 ? 'de' : 'en';
      this.$forceUpdate();
    });

    $i18n.on('languageChanged', () => {
      this.locale = $i18n.language.indexOf('de') > -1 ? 'de' : 'en';
      this.$forceUpdate();
    });

    this.configBranding();
  }

  configBranding() {
    // Configure application branding
    const schema: TBrandItem = getBrandSchema();
    this.$store.dispatch('setBrandSchema', schema);

    const favicon = document.getElementById('favicon') as HTMLAnchorElement;
    favicon.href = schema.favicon;
    this.brandName = schema.name;
    document.title = this.brandName;

    // configure colors based on brandschema
    Object.keys(schema.colors).map((colorKey) => {
      const color = schema.colors[colorKey];
      this.$refs.app.style.setProperty(`--${colorKey}`, color);
    });
  }
}
