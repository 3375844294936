


























import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'SelectLanguage',
})
export default class SelectLanguage extends Vue {
  $i18n: any;

  mounted() {
    this.$i18n.on('loaded', () => {
      this.$forceUpdate();
    });
  }

  // change lng
  private setLocale(locale) {
    this.$i18n.changeLanguage(locale);
  }
}
