














































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'ExporoLoading',
})
export default class ExporoLoading extends Vue {
  @Prop({ default: true, required: true }) private isLoading?: Boolean;
}
