import router from '@/router';
declare global {
    interface Window {
      dataLayer: Array<any>;
    }
}

const appId = () => {
    const BrandName = process.env.VUE_APP_DEFAULT_BRAND_SCHEMA;
    var appId = `user_${BrandName}_de`;
    if (BrandName === undefined && window.location.host.indexOf('propvest') > -1) {
        appId = 'user_propvest_de';
    } else if (BrandName === undefined) {
        appId = 'user_exporo_de';
    }

    return appId;
};

export enum gtmActions {
    USER_AUTH_SUCCESSFUL = 'USER_AUTH_SUCCESSFUL',
    USER_ACTIVATION_SUCCESSFUL = 'USER_ACTIVATION_SUCCESSFUL',
    USER_REGISTRATION_SUCCESSFUL = 'USER_REGISTRATION_SUCCESSFUL',
    ONBOARDING_STATUS = 'ONBOARDING_STATUS',    
    IDENTIFICATION_START = 'IDENTIFICATION_START',
    EXPLORATION_QUESTION = 'EXPLORATION_QUESTION',
    EMONEY_WALLET_START = 'EMONEY_WALLET_START',
    EMONEY_WALLET_ERROR = 'EMONEY_WALLET_ERROR',
    BLOCKCHAIN_WALLET_START = 'BLOCKCHAIN_WALLET_START'
}

export function gtmTrack (trackAction: gtmActions, data: any = null) {
    let eventProperty = {};
    switch (trackAction) {
        case gtmActions.USER_AUTH_SUCCESSFUL:
            if(!data || !data.userId) return false;

            eventProperty =  { onboarding_type: data.onboardingType };

            window.dataLayer.push({
                'appId': appId(),
                'userID': data.userId || null,
                'event': 'gtmEvent',
                'eventCategory': 'user',
                'eventAction': 'trackUser',
                'eventLabel': 'auth',
                'eventProperty': eventProperty,
            });
            
            break;
        case gtmActions.USER_ACTIVATION_SUCCESSFUL:
            window.dataLayer.push({
                'appId': appId(),
                'event': 'gtmEvent',
                'eventCategory': 'accountCreation',
                'eventAction': 'activate',
                'eventLabel': null, 
                'eventProperty': null, 
            });

            break;
        case gtmActions.USER_REGISTRATION_SUCCESSFUL:
            window.dataLayer.push({
                'appId': appId(),
                'event': 'gtmEvent',
                'eventCategory': 'accountCreation',
                'eventAction': 'register',
                'eventLabel': null, 
                'eventProperty': null, 
            });

            break;
        case gtmActions.ONBOARDING_STATUS:
            if(!data || !data.onboardingStatus
                || router.currentRoute.name === 'MiddlewarePage'
                || router.currentRoute.name === 'Onboarding'
            ) return false;

            eventProperty =  { onboarding_type: data.onboardingType, ...data.onboardingStatus };
            const label = router.currentRoute.path
                .replace('/onboarding/', '')
                .replace('/profile', 'profile')
                .replace('final', 'success');

            window.dataLayer.push({
                'appId': appId(),
                'event': 'gtmEvent',
                'eventCategory': 'onboarding',
                'eventAction': 'track',
                'eventLabel': label,
                'eventProperty': eventProperty,		
            });

            break;
        case gtmActions.IDENTIFICATION_START:
            
            eventProperty =  { onboarding_type: data.onboardingType };

            window.dataLayer.push({
                'appId': appId(),
                'event': 'gtmEvent',
                'eventCategory': 'ident',
                'eventAction': 'start',
                'eventLabel': null,
                'eventProperty': eventProperty,	
            });

            break;
        case gtmActions.EXPLORATION_QUESTION:
            if(
                !data ||
                !data.onboardingType || 
                !data.questionTotal ||
                !data.questionIndex ||
                !data.questionText ||
                !data.questionAnswer
            )
            return false;

            const questioneventProperty = {
                'onboarding_type': data.onboardingType,
                'total_questions': data.questionTotal,
                'question_index': data.questionIndex, 
                'question_text_en': data.questionText,
                'selected_answer_text_en': data.questionAnswer,
            };

            eventProperty =  { ...questioneventProperty };

            window.dataLayer.push({
                'appId': appId(),
                'event': 'gtmEvent',
                'eventCategory': 'survey',
                'eventAction': 'answer',
                'eventLabel': data.onboardingType,
                'eventProperty': eventProperty,
            });

            break;
        case gtmActions.EMONEY_WALLET_START:

            eventProperty =  { onboarding_type: data.onboardingType };

            window.dataLayer.push({
                'appId': appId(),
                'event': 'gtmEvent',
                'eventCategory': 'mangopay_wallet',
                'eventAction': 'start',
                'eventLabel': null,
                'eventProperty': eventProperty,
            });
 
            break;
        case gtmActions.EMONEY_WALLET_ERROR:
            if(!data || !data.iban) return false;

            eventProperty =  { onboarding_type: data.onboardingType };

            window.dataLayer.push({
                'appId': appId(),
                'event': 'gtmEvent',
                'eventCategory': 'mangopay_wallet',
                'eventAction': 'fail',
                'eventLabel': data.iban,
                'eventProperty': eventProperty,	
            });
    
            break;
        case gtmActions.BLOCKCHAIN_WALLET_START:

            eventProperty =  { onboarding_type: data.onboardingType };

            window.dataLayer.push({
                'appId': appId(),
                'event': 'gtmEvent',
                'eventCategory': 'blockchain_wallet',
                'eventAction': 'start',
                'eventLabel': null,
                'eventProperty': eventProperty,
            });

            break;
        default:
            return false;
    }

    return  true;
};