import _ from 'lodash';
import Vue from 'vue';
import axios from 'axios';
import * as Sentry from '@sentry/browser';
import router from '@/router';
import store from '@/store';

const ignoreStatus = [401, 403, 422];

export function configAxios() {
  axios.interceptors.response.use((response: any) => {
    return response;
  }, error => {
    const { response } = error;

    if (response) {
      if (response.status === 401) {
        // user session probably expired, send user to middleware / signin flow
        const { currentRoute } = router;
        const { redirectURL } = currentRoute.query;
        const returnURL = redirectURL ? redirectURL : `${window.location.origin}${window.location.pathname}#/`;
        
        // show main loader while token refresh is handled in middleware page
        store.commit('setLoaded', { loaded: false });
        router.push({ name: 'MiddlewarePage', query: { ...currentRoute.query, to: returnURL } });

        return Promise.reject('unautenticated');
      }

      if (!ignoreStatus.includes(response.status)) {
        // track problematic request details to sentry
        Sentry.withScope(function (scope) {
          const URL = parseURL(error.config.url);
          // group errors together based on their method, URL and response status, and the current route URL
          scope.setFingerprint([error.config.method, URL, response.status, window.location.hash]);
          Sentry.setExtra('stack', error.stack);

          let request: any = {};
          if (_.get(error, 'response.config.url')) {
            request = {
              method: error.config.method,
              url: error.config.url,
              status: error.response.status,
              error: error.toString(),
              response: JSON.stringify(error.response.data),
            };
          }

          if (_.get(error, 'response.config.data')) {
            request.payload = error.response.config.data;
          }

          Sentry.setContext('request', request);

          // changes the way the error is presented in sentry
          error.name = 'Request error';
          error.stack = `${error.config.method.toUpperCase()} ${URL}`;
          error.message = `${error.toString()} at ${window.location.hash}`;

          Sentry.captureException(error);
        });
      }
    }

    return Promise.reject(error);
  });

  return axios;
}

/**
 * Parses a url and replaces numbers between '/' for ':number' expression
 * Useful to identify possible dinamic ids in the urls strings
 * 
 * @param url { string }
 */
function parseURL(url: string) {
  return url.split('/')
    .map(part => Number(part) > 0 ? ':number' : part)
    .join('/')
    .toString();
}