



















import { Component, Prop, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { TBrandItem } from '@/utils/brand-schemas';

@Component({
  name: 'GenericSpinner',
  computed: mapGetters(['brandSchema']),
})
export default class GenericSpinner extends Vue {
  @Prop({ default: true, required: true }) private isLoading?: Boolean;

  private brandSchema!: TBrandItem;

  get baseColor() {
    return this.brandSchema.colors.primary;
  }

  get highlightColor() {
    return this.brandSchema.colors.secondary;
  }

  get colors() {
    return `${this.highlightColor};${this.baseColor};${this.baseColor}`;
  }
}
