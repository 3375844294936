import Vue from 'vue';
import VueRouter, { Route } from 'vue-router';
import store from '@/store';
import authMiddleware from '@/middleware/auth';
import { getBrandSchema } from '@/utils/helpers';

Vue.use(VueRouter);

const BRAND = getBrandSchema().name;

const routes = [
  {
    path: '/signin',
    name: 'SignIn',
    component: () => import(/* webpackChunkName: "Auth" */ '@/views/authentication/SignIn.vue'),
    meta: {
      title: `${BRAND} - Login`,
    },
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: () => import(/* webpackChunkName: "Auth" */ '@/views/authentication/ResetPass.vue'),
    meta: {
      title: `${BRAND} - Reset password`,
    },
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import(/* webpackChunkName: "Profile" */ '@/views/profile/Profile.vue'),
    meta: {
      title: `${BRAND} - Profile`,
      middleware: authMiddleware,
    },
  },
  {
    path: '/app/profile',
    name: 'ProfileWebView',
    component: () => import(/* webpackChunkName: "AppProfile" */ '@/views/profile/ProfileWebView.vue'),
    meta: {
      title: `${BRAND} - Profile`,
      middleware: authMiddleware,
    },
  },
  {
    path: '/app/tax-information',
    name: 'TaxInformationWebView',
    component: () => import(/* webpackChunkName: "AppTaxInformation" */ '@/views/profile/TaxInformationWebView.vue'),
    meta: {
      title: `${BRAND} - Tax-Information`,
      middleware: authMiddleware,
    },
  },
  {
    path: '/onboarding/',
    component: () => import(/* webpackChunkName: "Onboarding" */ '@/views/onboarding/Onboarding.vue'),
    meta: {
      middleware: authMiddleware,
    },
    children: [
      {
        path: '',
        name: 'Onboarding',
        component: () => import(/* webpackChunkName: "Onboarding" */ '@/views/onboarding/Show.vue'),
        props: (route: Route) => ({ type: route.query.type, redirectURL: route.query.redirectURL }),
        meta: {
          title: `${BRAND}`,
          middleware: authMiddleware,
        },
      },
      {
        path: 'personal-information',
        name: 'User personal information',
        component: () => import(/* webpackChunkName: "Onboarding" */ '@/views/onboarding/steps/PersonalInformation.vue'),
        meta: {
          title: `${BRAND} - Personal information`,
          middleware: authMiddleware,
        },
      },
      {
        path: 'taxes',
        name: 'User taxes information',
        component: () => import(/* webpackChunkName: "Onboarding" */ '@/views/onboarding/steps/Taxes.vue'),
        meta: {
          title: `${BRAND} - Taxes information`,
          middleware: authMiddleware,
        },
      },
      {
        path: 'toc',
        name: 'TermsAndConditions',
        component: () => import(/* webpackChunkName: "Onboarding" */ '@/views/onboarding/steps/TermsAndConditions.vue'),
        meta: {
          title: `${BRAND} - Terms and conditions`,
          middleware: authMiddleware,
        },
        props: (route: Route) => ({ type: route.query.type, onboarding: route.query.onboarding }),
      },
      {
        path: 'ident',
        name: 'Ident',
        component: () => import(/* webpackChunkName: "Onboarding" */ '@/views/onboarding/steps/Ident.vue'),
        meta: {
          title: `${BRAND} - Identification`,
          middleware: authMiddleware,
        },
        props: (route: Route) => ({ type: route.query.type, onboarding: route.query.onboarding }),
      },
      {
        path: 'final',
        name: 'Final',
        component: () => import(/* webpackChunkName: "Onboarding" */ '@/views/onboarding/steps/Final.vue'),
        meta: {
          title: `${BRAND} - Onboarding complete!`,
          middleware: authMiddleware,
        },
        props: (route: Route) => ({ type: route.query.type, onboarding: route.query.onboarding }),
      },
    ],
  },
  {
    path: '/middleware',
    name: 'MiddlewarePage',
    component: () => import('@/views/MiddlewarePage.vue'),
  },
  {
    path: '*',
    redirect: '/onboarding/',
  },
];

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
});

// Creates a `nextMiddleware()` function which not only
// runs the default `next()` callback but also triggers
// the subsequent Middleware function.
function nextFactory(context: any, middleware: any, index: any) {
  const subsequentMiddleware = middleware[index];
  // If no subsequent Middleware exists,
  // the default `next()` callback is returned.
  if (!subsequentMiddleware) return context.next;

  return (...parameters: any[]) => {
    // Run the default Vue Router `next()` callback first.
    context.next(...parameters);
    // Then run the subsequent Middleware with a new
    // `nextMiddleware()` callback.
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}

router.beforeEach((to, from, next) => {
  // save query params in store (and localstorage) and remove them from url
  const { query } = to;
  const { type, successUrl, cancelUrl } = query;

  if (successUrl) {
    store.commit('setSuccessUrl', successUrl);
    delete query.successUrl;

    return next(to);
  }

  if (type) {
    store.commit('setValidationType', type);
    delete query.type;

    return next(to);
  }

  if (cancelUrl) {
    store.commit('setCancelUrl', cancelUrl);
    delete query.cancelUrl;

    return next(to);
  }

  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];

    const context = {
      from,
      next,
      router,
      to,
    };
    const nextMiddleware = nextFactory(context, middleware, 1);

    return middleware[0]({ ...context, next: nextMiddleware });
  }

  return next();
});

router.afterEach((to, from) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  Vue.nextTick(() => {
    document.title = to.meta.title || BRAND;
  });
});

export default router;
