














































import { Component, Prop, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import AppLogo from '../../components/common/AppLogo.vue';
import SelectLanguage from '../../components/reusable/Select/SelectLanguage.vue';
import UserSelect from '../../components/reusable/Select/UserSelect.vue';
import { brandNames } from '@/enum/brands';

@Component({
  name: 'AppHeader',
  components: {
    AppLogo,
    SelectLanguage,
    UserSelect,
  },
  computed: {
    ...mapGetters(['brandSchema', 'isAuthenticated', 'getSuccessUrl']),
  },
})
export default class AppHeader extends Vue {
  getSuccessUrl: any; // computed
  brandSchema: any; // computed
  $route: any; // computed

  @Prop({ default: null, required: false }) title?: string;

  get homeLink() {
    if (this.isPropvest) {
      if (this.isAuthRoute) {
        return 'https://propvest.de';
      }

      return this.brandSchema.url;
    }

    if (this.getSuccessUrl) {
      return this.getSuccessUrl;
    }

    if (this.$route.query.redirectURL) {
      return this.$route.query.redirectURL;
    }

    return this.brandSchema.url;
  }

  get isProfileRoute() {
    return this.$route.name === 'Profile';
  }
  get isAuthRoute() {
    return this.$route.name === 'SignIn' || this.$route.name === 'ResetPassword';
  }
  get isPropvest() {
    return this.brandSchema.name === brandNames.PROPVEST;
  }

  get isExporo() {
    return this.brandSchema.name === brandNames.EXPORO;
  }
}
