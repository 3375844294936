





import { Component, Prop, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import ExporoLoading from '@/components/common/loaders/ExporoLoading.vue';
import GenericSpinner from '@/components/common/loaders/GenericSpinner.vue';
import { TBrandItem } from '@/utils/brand-schemas';
import { brandNames } from '@/enum/brands';

@Component({
  name: 'app-loader',
  components: {
    ExporoLoading,
    GenericSpinner,
  },
  computed: mapGetters(['brandSchema']),
})
export default class AppLoader extends Vue {
  @Prop({ default: true, required: true }) private isLoading?: Boolean;

  private brandSchema!:TBrandItem;

  get isExporo(){    
    return this.brandSchema.name === brandNames.EXPORO;
  }
}
