







import { Component, Prop, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { TBrandItem } from '@/utils/brand-schemas';

@Component({
  name: 'app-logo',
  computed: {
    ...mapGetters(['brandSchema']),
  },
})
export default class AppLogo extends Vue {
  @Prop({ default: 'mbLogoSrc', required: false }) private type!: string;

  private brandSchema!: TBrandItem;

  get logoContainsName() {
    return this.brandSchema.logoContainsName;
  }

  get brandName() {
    return this.brandSchema.name;
  }

  get logoUrl() {
    return this.brandSchema[this.type];
  }

  get imgURL() {
    return require(`@/assets/${this.logoUrl}`);
  }
}
