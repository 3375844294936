/**
 * Validation types
 *
*/
export enum validationTypes {
  WPHG_VERMITTLUNG_BAADER = 'wphgVermittlungBaader',
  WPHG_VERMITTLUNG_STO = 'wphgVermittlungSTO',
  WPHG_VERWALTUNG_BAADER = 'wphgVerwaltungBaader',
  WPHG_VERWALTUNG_STO = 'wphgVerwaltungSTO',
  VERMANLG = 'vermanlg',
  EMONEY = 'emoney',
}

export default validationTypes;
