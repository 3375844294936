import { detect } from 'detect-browser';
import { TBrandItem, investmentcycle, exporo, propvest, exporoApp } from './brand-schemas';

export const envVariables = {
  VUE_APP_BACKEND_BASE_DOMAIN: replaceByTopLevelDomain(process.env.VUE_APP_BACKEND_BASE_DOMAIN!),
  VUE_APP_SESSION_BASE_DOMAIN: replaceByTopLevelDomain(process.env.VUE_APP_SESSION_BASE_DOMAIN!),
};

export function replaceByTopLevelDomain(domain: string) {
  if (window.location.host.includes('user.exporo.de')) {
    domain = domain.replace('user.exporo.com', 'user.exporo.de');
  }

  return domain;
};

export const isJson = (str: string) => {
  try {
    return JSON.parse(str);
  } catch {
    return false;
  }
};

const brands: {
  [key: string]: TBrandItem
} = {
  investmentcycle,
  exporo,
  propvest,
  exporoApp,
};

export const getBrandSchema = (redirectURL?: string, successUrl?: string) => {
  const brand: string | undefined = Object.keys(brands).find(item => window.location.host.includes(item));
  if(!window.location.href.includes('signin') && (window.location.href.includes('/app/profile') || window.location.href.includes('/app/tax-information'))) {
    return brands['exporoApp'];
  }

  if (brand) {
    return brands[brand];
  }

  if (process.env.VUE_APP_DEFAULT_BRAND_SCHEMA) {
    return brands[process.env.VUE_APP_DEFAULT_BRAND_SCHEMA];
  }

  return brands['exporo'];
};

export const brandUserServiceRedirectURL = (url: string, redirectURL?: string) => {
  //TODO dynamization
  if (window.location.href.includes('user.propvest.de')) {
    return url.replace('exporo.com', 'propvest.de');
  }

  return url;
};

export const supportMultiTLDForSafariCookies = (redirectTargetURL) => {
  const defaultDomain = replaceByTopLevelDomain(process.env.VUE_APP_SESSION_BASE_DOMAIN!);

  if (defaultDomain.includes('.amazonaws.com')) {
    // Raw ApiGateway domain without custom domain integration.
    // Happens in feature branch environments. Stage and Prod have custom domains.
    return defaultDomain;
  }

  //Sometimes a wrong TLD is taken because of second redirect parameter, attached during the onboarding
  const savedRedirectTargetURL = redirectTargetURL.split('?')[0];

  //for propvest redirect always via propvest
  if (savedRedirectTargetURL.includes('propvest.de')) {
    return defaultDomain.replace('exporo.com', 'propvest.de');
  }

  const browser = detect();

  // To prevent Safari's cookie behavior
  if (browser && browser.name === 'safari') {
    if (savedRedirectTargetURL.includes('exporo.de')) {
      return defaultDomain.replace('exporo.com', 'exporo.de');
    } else if (savedRedirectTargetURL.includes('exporo.nl')) {
      return defaultDomain.replace('exporo.com', 'exporo.nl');
    } else if (savedRedirectTargetURL.includes('exporo.fr')) {
      return defaultDomain.replace('exporo.com', 'exporo.fr');
    } else if (savedRedirectTargetURL.includes('exporo.io')) {
      return defaultDomain.replace('exporo.com', 'exporo.io');
    }
  }

  return defaultDomain;
};

export function parseJwt(token: string) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
};

export const delay = (ms: number) => (new Promise(resolve => setTimeout(resolve, ms)));

export function isObject(value) {
  return value && typeof value === 'object' && value.constructor === Object;
}