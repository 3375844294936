

































import { Component, Prop, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import AppLogo from '@/components/common/AppLogo.vue';
import { brandNames } from '@/enum/brands';

@Component({
  name: 'AppFooter',
  components: {
    AppLogo,
  },
  computed: {
    ...mapGetters(['brandSchema', 'isAuthenticated', 'getSuccessUrl']),
  },
})
export default class AppFooter extends Vue {
  getSuccessUrl: any; // computed
  brandSchema: any; // computed
  $route: any;

  get homeLink() {
    if (this.isPropvest) {
      if (this.isAuthRoute) {
        return 'https://propvest.de';
      }

      return this.brandSchema.url;
    }

    if (this.getSuccessUrl) {
      return this.getSuccessUrl;
    }

    if (this.$route.query.redirectURL) {
      return this.$route.query.redirectURL;
    }

    return this.brandSchema.url;
  }

  get isAuthRoute() {
    return this.$route.name === 'SignIn' || this.$route.name === 'ResetPassword';
  }

  get isPropvest() {
    return this.brandSchema.name === brandNames.PROPVEST;
  }

  get isExporo() {
    return this.brandSchema.name === brandNames.EXPORO;
  }
  
  mounted() {
    const { $i18n } = Vue.prototype;

    $i18n.on('languageChanged', () => {
      this.$forceUpdate();
    });
  }
}
