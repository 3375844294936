class LocalStorage {
    public static add(key: string, value: any) {
        localStorage.setItem(key, String(value));
    }

    public static get(key: string): string | null {
        if (LocalStorage.has(key)) {
            return localStorage.getItem(key);
        }

        return null;
    }

    public static remove(key: string) {
        if (LocalStorage.has(key)) {
            localStorage.removeItem(key);
        }
    }

    public static has(key: string): boolean {
        return null !== localStorage.getItem(key);
    }
}

export default LocalStorage;
