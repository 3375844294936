/**
 * Onboarding origins
 * 
*/
export enum OnboardingOrigin {
    USER = 'user.exporo', // user wants to register in exporo.de
    PROPVEST = 'propvest', // user wants to register in propvest.de
    STRATEGIES = 'strategies', // user wants to register in strategies.exporo.com
  };
  
  export default OnboardingOrigin;
  