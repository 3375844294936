import Vue from 'vue';
import I18nPlugin from '@exporo/educts-component-i18n';
import LocalStorage from '@/utils/LocalStorage';

declare module 'vue/types/vue' {
  interface Vue {
    $i18n: any,
    $t: any
  }
}

export async function configI18n() {
  Vue.use(I18nPlugin);

  const { $i18n } = Vue.prototype;

  // set only these languages to show as option
  $i18n.activeLanguages = [{label: 'DEU', lang:'de-DE'}, {label: 'ENG', lang:'en-GB'}];

  const locale = LocalStorage.has('i18nextLng')
    ? LocalStorage.get('i18nextLng')
    : 'de-DE';

  $i18n.on('initialized', () => {
    $i18n.changeLanguage(locale);
  });
}
