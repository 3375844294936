import axios from 'axios';
import Vue from 'vue';
import Vuex from 'vuex';
import router from '@/router';
import { parseJwt, isObject } from '@/utils/helpers';
import { getCurrentSession } from '@/utils/Auth';
import { gtmTrack, gtmActions } from '@/utils/gmt';
import { TBrandItem } from '@/utils/brand-schemas';
import validationTypes from '@/enum/validationTypes';
import OnboardingOrigin from '@/enum/onboarding/origin';

Vue.use(Vuex);

const DEFAULT_VALIDATION_TYPE = validationTypes.WPHG_VERMITTLUNG_STO;

function get(key: string, defaultValue: any) {
  const query = router.currentRoute.query.to ? router.currentRoute.query.to.toString().split('?')[1] : null;

  if (query) {
    const searchParams = new URLSearchParams(query);
    if (searchParams.has(key)) {
      window.localStorage.setItem(key, <string>searchParams.get(key));

      return <string>searchParams.get(key);
    }
  }

  if (window.localStorage.getItem(key) && window.localStorage.getItem(key) !== 'null' && window.localStorage.getItem(key) !== 'undefined') {
    return window.localStorage.getItem(key);
  }

  return defaultValue;
}

export default new Vuex.Store({
  state: {
    refreshToken: '',
    accessToken: '',
    idToken: '',
    brandSchema: {},
    loaded: false,
    isIdentified: false,
    user: {
      email:'',
      email_verified: false,
      user_id:'',
    }, 
    naturalUser: {},
    successUrl: window.localStorage.getItem('successUrl') || null,
    cancelUrl: window.localStorage.getItem('cancelUrl') || document.referrer,
    cancelAble: window.localStorage.getItem('cancelAble') || true,
    validationType: window.localStorage.getItem('type') || null,
    onboardingComponents: { // stepper main menu
      hasEnoughMetaData: {
        component: 'User MetaData',
        title: 'menu.userMetaData',
        valid: false,
        show: false,
        path: '/onboarding/personal-information',
        children: {
          taxes: {
            component: 'Taxes',
            title: 'menu.taxes',
            valid: false,
            show: false,
            path: '/onboarding/taxes',
            children: [],
          },
        },
      },
      
      hasAcceptLatestToC: {
        component: 'Terms of Services',
        title: 'menu.termsOfServices',
        valid: false,
        show: false,
        path: '/onboarding/toc',
        children: [],
        hideOnValidationType: [
          validationTypes.EMONEY,
        ],
      },

      isIdentified: {
        component: 'Identification',
        title: 'menu.identification',
        valid: false,
        show: false,
        path: '/onboarding/ident',
        children: [],
        hideOnValidationType: [
          validationTypes.EMONEY,
        ],
      },
    },
    onboardingStatus: undefined, // service validation results
    registerSession: undefined,
  },
  getters: {
    getSuccessUrl: (state) => {
      return state.successUrl;
    },
    getCancelUrl: (state) => {
      return state.cancelUrl;
    },
    validationType: (state) => {
      if (state.validationType) {
        return state.validationType;
      }

      return get('type', DEFAULT_VALIDATION_TYPE);
    },
    onboardingOrigin: (state) => {
      const successURL = (state.successUrl && state.successUrl != 'undefined') ? state.successUrl : (state.brandSchema as TBrandItem).url || window.location.host;

      if(successURL.includes(OnboardingOrigin.PROPVEST)){
        return OnboardingOrigin.PROPVEST;
      }

      if(successURL.includes(OnboardingOrigin.STRATEGIES)){
        return OnboardingOrigin.STRATEGIES;
      }

      return OnboardingOrigin.USER;
    },
    // stepper main menu
    getOnboardingComponents: (state) => {
      return state.onboardingComponents;
    },
    // service validation results
    onboardingStatus: (state) => {
      return state.onboardingStatus;
    },
    getIsCancelAble: (state) => {
      return state.cancelAble;
    },
    isAuthenticated: (state) => {
      return !!state.refreshToken;
    },
    isIdentified: (state) => {
      return state.isIdentified;
    },
    isLoaded: (state) => {
      return state.loaded;
    },
    isOnboarded: (state) => {
      return state.onboardingComponents.hasEnoughMetaData.valid
        && state.onboardingComponents.hasAcceptLatestToC.valid
        && state.onboardingComponents.isIdentified.valid;
    },
    getToken: (state) => {
      return {
        refreshToken: state.refreshToken,
        accessToken: state.accessToken,
        idToken: state.idToken,
      };
    },
    user: (state) => {
      return state.user;
    },
    naturalUser: (state) => {
      return state.naturalUser;
    },
    registerSession: (state) => {
      return state.registerSession;
    },
    brandSchema: (state) => {
      return state.brandSchema;
    },
  },
  mutations: {
    setUser(state, user) {
      Vue.set(state, 'user', user);
    },
    setVerifiedEmail(state, status = true) {
      state.user.email_verified = status;
      Vue.set(state, 'user.email_verified', status);
    },
    setNaturalUser(state, naturalUser) {
      Vue.set(state, 'naturalUser', naturalUser);
    },
    setBrandSchema(state, schema) {
      Vue.set(state, 'brandSchema', schema);
    },
    setToken(state, { token }) {
      state.refreshToken = token.refreshToken;
      state.idToken = token.idToken;
      state.accessToken = token.accessToken;
    },
    setLoaded(state, { loaded }) {
      Vue.set(state, 'loaded', loaded);
    },
    setIsIdentified(state, isIdentified) {
      Vue.set(state, 'isIdentified', isIdentified);
    },
    setSuccessUrl(state, value) {
      if (value) {
        window.localStorage.setItem('successUrl', value);
      } else {
        window.localStorage.removeItem('successUrl');
      }

      Vue.set(state, 'successUrl', value);
    },
    setCancelUrl(state, value) {
      window.localStorage.setItem('cancelUrl', value);
      Vue.set(state, 'cancelUrl', value);
    },
    setCancelAble(state, value) {
      window.localStorage.setItem('cancelAble', value);
      Vue.set(state, 'cancelAble', value);
    },
    setValidationType(state, value) {
      window.localStorage.setItem('validationType', value);
      Vue.set(state, 'validationType', value);
    },
    setOnboardingComponents(state, value) {
      Vue.set(state, 'onboardingComponents', value);
    },
    setOnboardingStatus(state, value) {
      Vue.set(state, 'onboardingStatus', value);
    },
    setRegisterSession(state, payload) {
      Vue.set(state, 'registerSession', payload);
    },
  },
  actions: {
    setToken({ commit }, token) {
      commit('setToken', token);
    },
    setUser({ commit }, {idToken}) {
      const parsedToken = parseJwt(idToken);
      const user = {
        email: parsedToken.email,
        user_id: parsedToken.user_id,
        email_verified: !!parsedToken.email_verified,
      };

      commit('setUser', user);
    },
    setVerifiedEmail({ commit }, status) {
      commit('setVerifiedEmail', status);
    },
    setNaturalUser({ commit }, naturalUser) {
      commit('setNaturalUser', naturalUser);
    },
    setBrandSchema({ commit }, schema) {
      commit('setBrandSchema', schema);
    },

    fetchNaturalUser({ commit }){
      const currentSession = getCurrentSession();

      return axios
          .get(process.env.VUE_APP_BACKEND_BASE_DOMAIN + '/user/metaData', {
              headers: {
                  'Authorization': 'Bearer ' + currentSession.idToken,
              },
          })
          .then(response => {
              const { naturalUser } = response.data;
              commit('setNaturalUser', naturalUser);

              return Promise.resolve(naturalUser);
          })
          .catch(error => {
            return Promise.reject(error);
          });
    },

    async fetchOnboardingStatus({ commit, state, getters }){
      const {validationType, onboardingOrigin} = getters;
      let components = state.onboardingComponents;

      const currentSession = getCurrentSession();

      if(!currentSession.idToken){
        return Promise.reject(false);
      }

      return axios
        .get(`${process.env.VUE_APP_BACKEND_BASE_DOMAIN}/onboarding?type=${validationType}&domain=${onboardingOrigin}`, {
          headers: {
            'Authorization': 'Bearer ' + currentSession.idToken,
          },
        })
        .then(({data}) => {
          let onboardingStatus = data.components;
          if(onboardingStatus) {
            // Add tracking action to Google tag Manager dataLayer
            const trackData = { onboardingStatus: data, onboardingType: validationType};
            gtmTrack(gtmActions.ONBOARDING_STATUS, trackData);
          }

          const resKeys = Object.keys(onboardingStatus);
          resKeys.forEach((key) => {
            if (components.hasOwnProperty(key)) {
              if (isObject(onboardingStatus[key])) {
                const subKeys = Object.keys(onboardingStatus[key]);
                components[key]['valid'] = onboardingStatus[key]['personalInformation'];
                components[key]['show'] = true;

                subKeys.forEach((subKey) => {
                  if (components[key]['children'].hasOwnProperty(subKey)) {
                    components[key]['children'][subKey]['valid'] = onboardingStatus[key][subKey];
                    components[key]['children'][subKey]['show'] = true;
                  }
                });
              } else {
                if (!components[key].hasOwnProperty('hideOnValidationType')
                    || !components[key].hideOnValidationType.includes(validationType)
                ) {
                  components[key]['show'] = true;
                }
                components[key]['valid'] = onboardingStatus[key];
              }
            }
          });

          commit('setIsIdentified', !!components.isIdentified.valid );
          // service onboarding status results
          commit('setOnboardingStatus', data.components);
          // used to build the sidebar menu
          commit('setOnboardingComponents', components);

          return Promise.resolve(data);
      })
      .catch(error => {
        return Promise.reject(error);
      });
    },

    updateNaturalUser({ getters }, payload = {
      limitedFields: 'basic',
      type: getters.validationType,
      formData: {
        type: 'natural',
        naturalUser: null, // getters.naturalUser,
      }}) {

      if (!payload.formData.naturalUser) {
        return Promise.reject('updateNaturalUser error. Missing formData.naturalUser payload');
      }

      const currentSession = getCurrentSession();

      return axios.post(process.env.VUE_APP_BACKEND_BASE_DOMAIN + '/user/metaData', payload,
        {
          headers: { 'Authorization': 'Bearer ' + currentSession.idToken,
        },
      });
    },

    setRegisterSession({ commit }, payload){
      commit('setRegisterSession', payload);
    },
  },
  modules: {},
});
