/**
 * BRAND NAMES
 *
*/
export enum brandNames {
  EXPORO = 'Exporo',
  EXPORO_APP = 'ExporoApp',
  PROPVEST = 'PROPVEST',
  INVESTMENT_CYCLE = 'INVESTMENT CYCLE',
}

export default brandNames;
