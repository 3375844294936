import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Amplify, * as AmplifyModules from 'aws-amplify';
import Vuelidate from 'vuelidate';
import { VueMaskDirective } from 'v-mask';
import { AmplifyPlugin } from 'aws-amplify-vue';

import vuetify from '@/plugins/vuetify';
import { configI18n } from '@/plugins/i18n';
import { configAxios } from '@/plugins/axios';
import { configSentry } from '@/plugins/sentry';

const USER_POOL_ID = process.env.VUE_APP_USER_POOL_ID || '';
const AWS_REGION = USER_POOL_ID.split('_')[0];

configSentry();

const AWS_CONFIGURE = {
  'aws_cognito_region': AWS_REGION,
  'aws_user_pools_id': USER_POOL_ID,
  'aws_user_pools_web_client_id': process.env.VUE_APP_USER_POOL_CLIENT_ID,
  'authenticationFlowType': 'USER_PASSWORD_AUTH',
  'oauth': {
    'responseType': 'code',
  },
  storage: window.sessionStorage,
};

Vue.directive('mask', VueMaskDirective);

Vue.config.productionTip = false;
Amplify.configure(AWS_CONFIGURE);

Vue.use(AmplifyPlugin, AmplifyModules);
Vue.use(Vuelidate);

configI18n();

configAxios();

Vue.config.ignoredElements = ['custom-header', 'custom-footer','custom-button'];

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
  created() {
  },
}).$mount('#app');


