import store from '../store';
import { parseJwt } from '@/utils/helpers';
import { identifySentryUser } from '@/plugins/sentry';
import { gtmTrack, gtmActions } from '@/utils/gmt';

export const getCurrentSession = () => {
  const currentSession = store.getters.getToken;

  return currentSession;
};

declare global {
  interface Window {
    dataLayer: Array<any>;
  }
}

export const resumeSession = async (refreshToken: string) => {
  const USER_POOL_ID = process.env.VUE_APP_USER_POOL_ID || '';
  const AWS_REGION = USER_POOL_ID.split('_')[0];
  const USER_POOL_CLIENT_ID = process.env.VUE_APP_USER_POOL_CLIENT_ID;

  const endpoint = `https://cognito-idp.${AWS_REGION}.amazonaws.com/`;

  return await fetch(endpoint, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'X-Amz-Target': 'AWSCognitoIdentityProviderService.InitiateAuth',
      'Content-Type': 'application/x-amz-json-1.1',
    },
    cache: 'no-cache',
    body: JSON.stringify({
      ClientId: USER_POOL_CLIENT_ID,
      AuthFlow: 'REFRESH_TOKEN_AUTH',
      AuthParameters: {
        REFRESH_TOKEN: refreshToken,
      },
    }),
  })
  .then((res) => res.json())
  .then(res => {
    const idToken = res.AuthenticationResult.IdToken;
    const accessToken = res.AuthenticationResult.AccessToken;

    const decodedJWT = parseJwt(idToken);

    // Add tracking action to Google tag Manager dataLayer
    const trackData = {userId: decodedJWT.user_id, onboardingType: store.getters.validationType};
    gtmTrack(gtmActions.USER_AUTH_SUCCESSFUL, trackData);
   
    identifySentryUser(decodedJWT.user_id);

    store.dispatch({
      type: 'setToken',
      token: {
        idToken,
        accessToken,
        refreshToken,
      },
    });
    store.dispatch({
      type: 'setUser',
      idToken,
    });

    return {refreshToken: {token: refreshToken}, idToken: { jwtToken: idToken }};

  })
  .catch(res => {
    console.log('error happend ---->', res);
    
    return null;
  });
};

