import { brandNames } from '@/enum/brands';

export type TBrandItem = {
  default?: boolean,
  name: string,
  url: string,
  dtLogoLink: string,
  mbLogoLink: string,
  headerLogo: string,
  logoContainsName: boolean,
  backgroundImage?: string,
  footerLogo?: string,
  favicon: string,
  supportEmail:string,
  supportPhone:string,
  colors: {
    [key: string]: string,
    primary: string,
    secondary: string,
    accent: string,
    error: string,
    info: string,
    success: string,
    warning: string,
  },
}

export const investmentcycle: TBrandItem = {
  name: brandNames.INVESTMENT_CYCLE,
  favicon: './images/favicon.ico',
  url: process.env.VUE_APP_STRATEGIES_URL!,
  dtLogoLink: 'investmentcycle_logo.svg',
  mbLogoLink: 'investmentcycle_logo.svg',
  headerLogo: 'investmentcycle_logo.svg',
  logoContainsName: false,
  supportEmail:'support@exporo.com',
  supportPhone:'+49 (0) 40 / 210 91 73 - 00',
  colors: {
    primary: '#43273A',
    secondary: '#E81858',
    accent: '#E81858',
    error: '#E03D3D',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FFC107',
  },
};

export const exporo: TBrandItem = {
  name: brandNames.EXPORO,
  favicon: './images/favicon.ico',
  url: process.env.VUE_APP_EXPORO_FINANCING_URL!,
  dtLogoLink: 'logo/squared/exporo_logo.svg',
  mbLogoLink: 'logo/horizontal/exporo-logo-light.svg',
  headerLogo: 'logo/horizontal/exporo-logo-dark.svg',
  footerLogo: 'logo/icons/exporo_icon.svg',
  logoContainsName: true,
  supportEmail:'support@exporo.com',
  supportPhone:'+49 (0) 40 / 228 686 99 - 0',
  colors: {
    // https://www.figma.com/file/yTAx7nYzrWSM8yBcwlkkvP/EXP-Styleguide?node-id=0%3A1&viewport=-5504%2C258%2C0.4953627586364746
    info: '#2196F3',
    warning: '#FFC107',

    primary: '#27304D',
    'primary-1': '#f2f4f8',
    'primary-2': '#252b33',
    'primary-3': '#1f2845',
    'primary-4': '#161e37',

    accent: '#F7A600',
    secondary: '#F7A600',
    'secondary-1': '#FFF7E6',
    'secondary-2': '#FDEDCC',
    'secondary-3': '#FDE5B3',
    'secondary-4': '#f79400',
    'secondary-5': '#f48400',
    'secondary-6': '#f27400',

    'grey-900': '#303841',
    'grey-800': '#303841',
    'grey-700': '#575F6A',
    'grey-600': '#727B87',
    'grey-500': '#8F99A6',
    'grey-400': '#ACB7C6',
    'grey-300': '#BDC5D2',
    'grey-200': '#CED3DE',
    'grey-100': '#DFE1EA',
    'grey-50': '#ECEFF4',

    'text-light': '#ECEFF4',
    'text-dark': '#27304D',

    success: '#10b610',
    error: '#E03D3D',

    // product colors
    'product-1': '#3968AF',
    'product-2': '#86AC48',
    'product-3': '#27304D',

    'background-white': '#FFFFFF',
    'background-light': '#ECEFF4',
  },
};


export const exporoApp: TBrandItem = {
  name: 'ExporoApp',
  favicon: './images/favicon.ico',
  url: process.env.VUE_APP_PROPVEST_DASHBOARD_URL!,
  dtLogoLink: 'logo/squared/exporo_logo.svg',
  mbLogoLink: 'logo/horizontal/exporo-logo-light.svg',
  headerLogo: 'logo/horizontal/exporo-logo-dark.svg',
  footerLogo: 'logo/icons/exporo_icon.svg',
  logoContainsName: true,
  supportEmail:'support@exporo.com',
  supportPhone:'+49 (0) 40 / 228 686 99 - 0',
  colors: {
    // https://www.figma.com/file/AlbsYsoPS5GB0i0vz5pynp/Propvest-Styleguide?node-id=0%3A1
    info: '#2196F3',
    warning: '#FFC107',

    primary: '#1F4B7A',
    'primary-1': '#e7f3f1',
    'primary-2': '#A1D4CA',
    'primary-3': '#1F4B7A',
    'primary-4': '#409281',

    accent: '#99A5CC',
    secondary: '#99A5CC',
    'secondary-1': '#fdf4e6',
    'secondary-2': '#fbe9cc',
    'secondary-3': '#f9deb3',
    'secondary-4': '#DE8B03',
    'secondary-5': '#D48400',
    'secondary-6': '#CE8102',

    'grey-900': '#212121',
    'grey-800': '#2e2d2c',
    'grey-700': '#616161',
    'grey-600': '#757575',
    'grey-500': '#9e9e9e',
    'grey-400': '#bdbdbd',
    'grey-300': '#e3e3e3',
    'grey-200': '#eeeeee',
    'grey-100': '#f5f5f5',
    'grey-50': '#fafafa',

    'text-primary': '#717E8E',
    'text-light': '#FFFFFF',
    'text-dark': '#2E2D2C',

    success: '#10b610',
    error: '#9F2D2D',
    disabled: '#C6CCD2',

    // product colors
    'product-1': '#F9E9BF',
    'product-2': '#C4D7D7',
    'product-3': '#D5CDC1',

    'background-white': '#FFFFFF',
    'background-light': '#fafafa',
  },
};

export const propvest: TBrandItem = {
  name: brandNames.PROPVEST,
  favicon: './images/favicon_propvest.png',
  url: process.env.VUE_APP_PROPVEST_DASHBOARD_URL!,
  dtLogoLink: 'logo/squared/propvest_logo_200x150.svg',
  mbLogoLink: 'logo/horizontal/propvest_logo-light.svg',
  headerLogo: 'logo/horizontal/propvest_logo-dark.svg',
  footerLogo: 'logo/icons/propvest_icon.svg',
  backgroundImage: 'bg/propvest.png',
  logoContainsName: true,
  supportEmail:'support@propvest.com',
  supportPhone:'+49 40 797 29 57 - 0',
  colors: {
    // https://www.figma.com/file/AlbsYsoPS5GB0i0vz5pynp/Propvest-Styleguide?node-id=0%3A1
    info: '#2196F3',
    warning: '#FFC107',

    primary: '#4AA391',
    'primary-1': '#e7f3f1',
    'primary-2': '#A1D4CA',
    'primary-3': '#4AA391',
    'primary-4': '#409281',

    accent: '#E99100',
    secondary: '#E99100',
    'secondary-1': '#fdf4e6',
    'secondary-2': '#fbe9cc',
    'secondary-3': '#f9deb3',
    'secondary-4': '#DE8B03',
    'secondary-5': '#D48400',
    'secondary-6': '#CE8102',

    'grey-900': '#212121',
    'grey-800': '#2e2d2c',
    'grey-700': '#616161',
    'grey-600': '#757575',
    'grey-500': '#9e9e9e',
    'grey-400': '#bdbdbd',
    'grey-300': '#e3e3e3',
    'grey-200': '#eeeeee',
    'grey-100': '#f5f5f5',
    'grey-50': '#fafafa',

    'text-light': '#FFFFFF',
    'text-dark': '#2E2D2C',

    success: '#10b610',
    error: '#E03D3D',

    // product colors
    'product-1': '#F9E9BF',
    'product-2': '#C4D7D7',
    'product-3': '#D5CDC1',

    'background-white': '#FFFFFF',
    'background-light': '#fafafa',
  },
};
