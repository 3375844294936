import Vue from 'vue';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';

const SENTRY_DSN = process.env.VUE_APP_SENTRY_DSN;

const isLocal = window.location.origin.includes('http://localhost');
const environment = window.location.host === 'user.exporo.com' ? 'production' : 'development';

export function configSentry() {
  // configure Sentry
  // allow error tracking in staging / prod
  if (!isLocal && environment) {
    Sentry.init({
      dsn: SENTRY_DSN,
      integrations: [new Integrations.Vue({ Vue, attachProps: true, logErrors: true})],
      environment,
      ignoreErrors: [
        'Error: Request aborted',
        'ChunkLoadError',
        'UnhandledRejection',
        'NavigationDuplicated',
      ],
    });
  }
}

export function identifySentryUser(userId = ''){
  if (!isLocal && environment) {
    // Capture user
    // https://docs.sentry.io/enriching-error-data/additional-data/?platform=javascript#capturing-the-user
    Sentry.setUser({
      id: userId,
    });
    // Set user aditional information, as well as tags and further extras
    // https://docs.sentry.io/enriching-error-data/additional-data/?platform=javascript#custom-data
  }
}