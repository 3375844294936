



























import axios from 'axios';
import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { envVariables } from '@/utils/helpers';
import { getCurrentSession } from '@/utils/Auth';
import { request } from '@/utils/services';

const domain = envVariables.VUE_APP_BACKEND_BASE_DOMAIN;

@Component({
  name: 'UserSelect',
  computed: {
    ...mapGetters(['naturalUser']),
  },
})
export default class UserSelect extends Vue {
  $i18n: any;

  private isMenuVisible: Boolean = false;
  private focusedIndex: number = 0;

  mounted() {
    this.$i18n.on('loaded', () => {
      this.$forceUpdate();
    });
  }

  private signout() {
    const { idToken } = getCurrentSession();
    const payload = {
      redirectURL: `${window.location.origin}${window.location.pathname}`,
      idToken,
    };
    request(`${domain}/user/session/signout`, payload);
  }
}
