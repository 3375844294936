import store from '@/store';

interface IAuthMiddlewareProps {
  next: () => {},
  router: any,
  to: any
}

export const authMiddleware = async ({ next, router, to }: IAuthMiddlewareProps) => {
  if (!!store.state.refreshToken) {
    next();
  } else {
    router.push({ name: 'MiddlewarePage', query: {to: to.fullPath}});
  }
};

export default authMiddleware;
