export const request = (path: string, params: any, method: string='post') => {
  const form = document.createElement('form');
  form.method = method;
  form.action = path;
  for (const key in params) {
    const hiddenField = document.createElement('input');
    hiddenField.type = 'hidden';
    hiddenField.name = key;
    hiddenField.value = params[key];

    form.appendChild(hiddenField);
  }

  document.body.appendChild(form);
  form.submit();
};